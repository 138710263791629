import authFactory from "./auth.js";
import usersFactory from "./users.js";
import storeFactory from "./store.js"
import postsFactory from "./posts.js"
import filesFactory from "./files.js"
import ordersFactory from "./orders.js"
import projectsFactory from "./projects.js";

export default {
  auth: authFactory(),
  users: usersFactory(),
  store: storeFactory(),
  posts: postsFactory(),
  files: filesFactory(),
  orders: ordersFactory(),
  projects: projectsFactory(),
};
