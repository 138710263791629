import {DEBUG, MOCK_USERS_API, BACKEND_BASE_URL} from "../../constants";
import axios from "axios";

class OrdersInterface {

}

class MockOrders extends OrdersInterface {

}

class Orders extends OrdersInterface {
    getAll(offset = 0, limit = 10) {
        let url = `/api/v1/store/orders/?offset=${offset}&limit=${limit}`
        return axios.get(url, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }

    getById(id) {
        let url = `/api/v1/store/orders/${id}/`
        return axios.get(url, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }

    update(order) {
        return axios.put(`/api/v1/store/orders/${order.id}/`, {
            comment: order.comment,
            status: order.status,
            manager: order.manager ? order.manager.id : null,
        }, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }

    sendOrderToManager(order_id) {
        return axios.post(`/api/v1/store/orders/${order_id}/send_to_manager/`, undefined, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }

    addProduct(order_id, product_id, count) {
        return axios.post(`/api/v1/store/orders/${order_id}/add/?product_id=${product_id}&count=${count}`, undefined, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }

    setComment(orderId, orderItemId, comment) {
        return axios.post(`/api/v1/store/orders/${orderId}/items/${orderItemId}/setcomment/`, {
            comment,
        })
    }

    deleteOrder(orderId) {
        return axios.delete(`/api/v1/store/orders/${orderId}/`);
    }


    deleteOrderItem(orderId, orderItemId) {
        return axios.delete(`/api/v1/store/orders/${orderId}/items/${orderItemId}/`)
    }


}

export default function ordersFactory() {
    if (MOCK_USERS_API) {
        return new MockOrders();
    } else {
        return new Orders();
    }
}
