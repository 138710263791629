import Vue from 'vue'
import Vuex from 'vuex'

// Модули
import ecommerceStoreModule from '@/store/app/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import appProducts from './app/products'
import productClipboard from './app/productClipboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    'app.products': appProducts,
    productClipboard,
  },
  strict: process.env.DEV,
})
