export default {
  namespaced: true,
  state: {
    productIds: [],
  },
  getters: {
    totalCount: state => {
      return state.productIds.length
    }
  },

  mutations: {
    RESET(state) {
      state.productIds = []
    },
    ADD_PRODUCT(state, id) {
      state.pickedProducts.push(id)
    },
    SET_PRODUCTS(state, ids) {
      state.productIds = ids
    },
  },
  actions: {
    reset(context) {
      context.commit('RESET')
    },

    addProduct(context, id) {
      context.commit('ADD_PRODUCT', id)
    },

    setProducts(context, ids) {
      context.commit('SET_PRODUCTS', ids)
    }
  },
}
