import { DEBUG, MOCK_AUTH_API, BACKEND_BASE_URL } from "./../../constants.js";
import axios from "axios";

class AuthInterface {
  signin(email, password) {}
  signup(
    email,
    phone,
    company_name,
    company_address,
    name,
    surname,
    middle_name,
    position,
    password
  ) {}
  forgot(email) {}
  restore(restore_key, password) {}
  change(current_password, new_password) {}
}

class MockAuth extends AuthInterface {
  signin(email, password) {
    return Promise.resolve(function() {
      return {
        email: "admin@hellfish.ru",
        password: "Admin@hellfish.ru",
      };
    });
  }
  signup(
    email,
    phone,
    company_name,
    company_address,
    name,
    surname,
    middle_name,
    position,
    password
  ) {}
  forgot(email) {}
  restore(restore_key, password) {}
  change(current_password, new_password) {}
}

class Auth extends AuthInterface {
  signin(email, password) {
    return axios.post(`/api/v1/auth/signin/`, {
      email,
      password,
    });
  }
  signup(
    email,
    phone,
    company_name,
    company_address,
    name,
    surname,
    middle_name,
    position,
    password
  ) {
    return axios.post(`/api/v1/auth/signup/`, {
      email,
      phone,
      company_name,
      company_address,
      first_name: name,
      last_name: surname,
      middle_name,
      position,
      password,
    });
  }
  logout() {
    return axios.get(`/api/v1/auth/logout/`);
  }
  confirm(confirm_key) {
    return axios.get(`/api/v1/auth/confirm/?confirm_key=${confirm_key}`);
  }
  forgot(email) {
    return axios.post(`/api/v1/auth/forgot/`, {
      email,
    });
  }
  restore(restore_key, password) {
    return axios.post(`/api/v1/auth/restore/`, {
      restore_key,
      password,
    });
  }
  change(current_password, new_password) {
    return axios.post(`/api/v1/auth/change/`, {
      current_password,
      new_password,
    });
  }
  confirmRetry(){
    return axios.get(`/api/v1/auth/confirm_retry/`);
  }
}

export default function authFactory() {
  if (MOCK_AUTH_API) {
    return new MockAuth();
  } else {
    return new Auth();
  }
}
