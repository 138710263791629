import {DEBUG, MOCK_AUTH_API, BACKEND_BASE_URL} from './../../constants.js';
import axios from 'axios';

class StoreInterface {
  loadProducts() {
  }

  filter(limit, offset, filters) {
  }

  getBasket() {
  }

  basketAddItem(product_id, count) {
  }

  getAllProductFilters() {
  }

  sort() {
  }

  basketToOrder() {

  }
}

class MockStore extends StoreInterface {
}

class Store extends StoreInterface {
  loadProducts(limit, offset = 0, sort, filters, query) {
    let url = `/api/v1/store/products/?offset=${offset}`;


    if (limit) {
      url += `&limit=${limit}`
    }
    if (sort.value) {
      url += `&sort=${sort.value}`
    }
    if (filters.categories.length > 0) {
      url += `&category=${filters.categories}`
    }
    if (filters.series.length > 0) {
      url += `&series=${filters.series}`
    }
    if (filters.brands.length > 0) {
      url += `&brands=${filters.brands}`
    }
    if (filters.voltages.length > 0) {
      url += `&voltages=${filters.voltages}`
    }
    if (filters.price.value.length == 2) {
      url += `&price_min=${filters.price.value[0]}&price_max=${filters.price.value[1]}`
    }
    if (query) {
      url += `&query=${query}`
    }
    return axios.get(url, {
      params: {
        currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
      }
    })
    //?limit=${limit}&offset=${offset}&sort=${sort.value}&category=${filters.categories}&manufacturer=${filters.manufacturer}&query=${query}`
    // if (query) {
    //     return axios.get(
    //         `/api/v1/store/products/?limit=${limit}&offset=${offset}&sort=${sort.value}&category=${filters.categories}&manufacturer=${filters.manufacturer}&query=${query}`,
    //         {limit, offset, sort, filters, query}
    //     );
    // }
    // return axios.get(
    //     `/api/v1/store/products/?limit=${limit}&offset=${offset}&sort=${sort.value}&category=${filters.categories}&manufacturer=${filters.manufacturer}`,
    //     {limit, offset, sort, filters}
    // );
  }

  getBasket() {
    return axios.get(`/api/v1/store/basket/my`, {
      params: {
        currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
      }
    });
  }

  basketAddItem(product_id, count) {
    return axios.post(
      `/api/v1/store/basket/add/?product_id=${product_id}&count=${count}`, undefined, {
        params: {
          currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
        }
      }
    );
  }

  basketDeleteItem(id) {
    return axios.delete(`/api/v2/basket-items/${id}`,{
        params: {
          currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
        }
      }
    );
  }

  basketToOrder() {
    return axios.post(
      `/api/v1/store/basket/doorder/`, undefined, {
        params: {
          currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
        }
      }
    )
  }

  getAllPriceLevels() {
    return axios.get(
      `/api/v1/store/price-levels/`, {
        params: {
          currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
        }
      }
    )
  }

  getAllProductFilters() {
    return axios.get(`/api/v1/store/product-filters/`, {
      params: {
        currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
      }
    });
  }

  basketToOrder() {
    return axios.post(`/api/v1/store/basket/doorder/`, undefined, {
      params: {
        currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
      }
    });
  }

  createCustomProduct({name, description, storageUnit, price, imageUrl}) {
    return axios.post(`/api/v1/store/products/`, {
      name,
      description,
      price,
      storage_unit: storageUnit,
      image_url: imageUrl,
    }, {
      params: {
        currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
      }
    });

  }
  updateCustomProduct(id, {name, description, count, storageUnit, imageUrl}) {
    return axios.put(`/api/v1/store/products/${id}/`, {
      name,
      description,
      count,
      storage_unit: storageUnit,
      image_url: imageUrl,
    }, {
      params: {
        currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
      }
    });
  }
}

export default function authFactory() {
  if (MOCK_AUTH_API) {
    return new MockStore();
  } else {
    return new Store();
  }
}
