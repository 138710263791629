export default {
  namespaced: true,
  state: {
    productPickedMode: false,
    pickedProducts: [], // item.count - количество, item.id - id товара
  },
  getters: {
    totalCount: state => {
      let totalCount = 0
      for (const product of state.pickedProducts) {
        totalCount += product.count
      }
      return totalCount
    }
  },

  mutations: {
    RESET(state) {
      state.pickedProducts = []
    },
    SET_PRODUCT_PICK_MODE(state, value = true) {
      state.productPickedMode = value
    },
    PICK_PRODUCT(state, {id, count}) {
      state.pickedProducts.push({id, count})
    }
  },
  actions: {
    reset(context) {
      context.commit('RESET')
    },

    setProductPickMode(context, value = true) {
      context.commit('SET_PRODUCT_PICK_MODE', value)
    },

    pickProduct(context, {id, count}) {
      context.commit('PICK_PRODUCT', {id, count})
    }
  },
}
