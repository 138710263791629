import {$themeBreakpoints} from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    user: null,
    cart: {
      amount: 0,
      count: 0,
      items: [],
    },
    order: {
      comment: '',
      create_at: '',
      id: null,
      manager: {
        id: null,
      },
      products: [],
      status: "IN_PROGRESS",
      totalAmount: 0,
      totalCount: 0,
      user: null,
    },
    project: {
      comment: null,
      rooms_order: null,
      currency: null,
      rooms: [
        {
          id: null,
          title: null,
          comment: null,
          areas_order: null,
          areas: [
            {
              id: null,
              title: null,
              comment: null,
              items_order: null,
            }
          ]
        }
      ]
    },
    selectedProjectZone: null,
    clipboard: {
      roomId: null,
      zoneId: null,
      projectId: null,
    }
  },
  getters: {
    currentBreakPoint: state => {
      const {windowWidth} = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    // selectedObject(state) {
    //
    //
    // }
  },

  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_USER(state, user) {
      state.user = user;
      try {


        const element = document.querySelector('#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(6)')
        if (state.user.account_type === 'CLIENT') {
          element.style.display = 'none';
        } else {

          element.style.display = 'block';

        }
      } catch (e) {
        console.info('Блок еще не создан')

      }
    },
    SET_CART(state, cart) {
      state.cart = cart
    },
    SET_ORDER(state, order) {
      state.order = order
    },
    SET_PROJECT(state, project) {
      state.project = project
    },
    RESET_CART(state) {
      state.cart = {
        amount: 0,
        count: 0,
        items: [],
      }
    },
    UPDATE_SELECTED_ZONE(state) {
      if (state.project == null) {
        state.selectedProjectZone = null;
        return;
      }

      try {
        //project
        if (state.selectedProjectZone.id == state.project.id) {
          state.selectedProjectZone = state.project;
          return;
        }
        //rooms
        for (let room of state.project.rooms) {
          if (state.selectedProjectZone.id == room.id) {
            state.selectedProjectZone = room;
            return;
          }
          for (let area of room.areas) {
            if (state.selectedProjectZone.id == area.id) {
              state.selectedProjectZone = area;
              return;
            }
          }

        }
      } catch (e) {
        console.warn(e)

      }


    },
    SET_SELECTED_PROJECT_ZONE(state, zone) {
      state.selectedProjectZone = zone
    },
    SET_CLIPBOARD_ROOM_ID(state, {roomId, projectId}) {
      state.clipboard.roomId = roomId
      state.clipboard.projectId = projectId
    },
    SET_CLIPBOARD_ZONE_ID(state, {projectId, roomId, zoneId}) {

      state.clipboard.projectId = projectId
      state.clipboard.roomId = roomId
      state.clipboard.zoneId = zoneId
    },
  },
  actions: {},
}
