import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    //  Новости
    {
      path: '/',
      name: 'posts-list',
      component: () => import('@/components/views/news/PostsList.vue'),
      meta: {
        pageTitle: 'Новости',
        breadcrumb: [
          {
            text: 'Новости',
            to: '/'
          },
        ],
      },
    },
    {
      path: '/posts/:slug',
      name: 'post-page',
      component: () => import('@/components/views/news/PostPage.vue'),
      meta: {
        pageTitle: 'Новости',
        breadcrumb: [
          {
            text: 'Новости',
            to: '/'
          },
        ],
      },

    },
    {
      path: '/post-edit/:id',
      name: 'post-edit',
      component: () => import('@/components/views/news/PostEdit.vue'),
      meta: {
        pageTitle: 'Новости',
        breadcrumb: [
          {
            text: 'Новости',
            to: '/'
          },
        ],
      },

    },
    {
      path: '/post-new/',
      name: 'post-new',
      component: () => import('@/components/views/news/PostNew.vue'),
      meta: {
        pageTitle: 'Новости',
        breadcrumb: [
          {
            text: 'Новости',
            to: '/'
          },
        ],
      },

    },

    //  Коммерция
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/components/views/orders/OrdersList.vue'),
      meta: {
        pageTitle: 'Заказы',
        breadcrumb: [
          {
            text: 'Заказы',
          },
        ],
      },
    },
    {
      path: '/orders/:id',
      name: 'order',
      component: () => import('@/components/views/orders/OrderPage.vue'),
      meta: {
        pageTitle: 'Заказ',
        breadcrumb: [
          {
            text: 'Заказы',
          },
        ],
      },
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/components/views/cart/Cart.vue'),
      meta: {
        pageTitle: 'Корзина',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Корзина',
          },
        ],
      },
    },
    {
      path: '/price',
      name: 'price',
      component: () => import('@/components/views/price-list/list/ProductsList.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Прайс-лист',
        breadcrumb: [
          {
            text: 'Прайс-лист',
          },
        ],
      },
    },

    //  Проекты
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/components/views/projects/ProjectsList.vue'),
      meta: {
        pageTitle: 'Проекты',
        breadcrumb: [
          {
            text: 'Список всех проектов',
            to: '/projects'
          },
        ],
      },
    },
    {
      path: '/project/:id',
      name: 'project-page',
      component: () => import('@/components/views/projects/Project.vue'),
      meta: {
        pageTitle: 'Проекты',
        breadcrumb: [
          {
            text: `Список всех проектов`,
            to: '/projects'
          },
        ],

      },
    },

    //  Клиенты
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/components/views/clients/ClientsList.vue'),
      meta: {
        pageTitle: 'Клиенты',
        breadcrumb: [
          {
            text: 'Клиенты',
          },
        ],
      },
    },
    {
      path: '/clients/:id',
      name: 'client-page',
      component: () => import('@/components/views/clients/client/Client.vue'),
    },

    //  Исключения
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/components/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/tech-works',
      name: 'tech-works',
      component: () => import('@/components/views/error/TechWorks.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    //  Аутентификация
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/views/user/login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/components/views/user/register/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot',
      component: () => import('@/components/views/user/forgot-password/Forgot.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset',
      component: () => import('@/components/views/user/reset-password/Reset.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/components/views/account/AccountSetting.vue'),
    },
    {
      path: '/account-confirm',
      name: 'account-confirm',
      component: () => import('@/components/views/user/confirm/Account-confirm.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

export default router
