import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
const numeral = require("numeral");

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import { VBTooltipPlugin } from 'bootstrap-vue'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(require('vue-moment'));

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VBTooltipPlugin)


// Number filters
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0.00"); // displaying other groupings/separators is possible, look at the docs
});

// import core styles
require('@core/scss/core.scss')
require('@core/assets/fonts/feather/iconfont.css')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
