import { DEBUG, MOCK_USERS_API, BACKEND_BASE_URL } from "../../constants";
import axios from "axios";

class FilesInterface {
  me() {}
}

class MockFiles extends FilesInterface {

}

class Files extends FilesInterface {
  getAll(){
    return axios.get(`/api/v1/files/`)
  }
  upload(file){
    const formData = new FormData();
    formData.append("file", file, file.name);
    return axios.post(`/api/v1/files/`, formData);
  }




}

export default function filesFactory() {
  if (MOCK_USERS_API) {
    return new MockFiles();
  } else {
    return new Files();
  }
}
