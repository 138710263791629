import { DEBUG, MOCK_USERS_API, BACKEND_BASE_URL } from "../../constants";
import axios from "axios";

class UsersInterface {
  me() {}
  updateProfile(){}
}

class MockUsers extends UsersInterface {
  me() {
    return Promise.resolve(function() {
      return {
        companyAddress: 'г. Рыбинск',
        email: 'admin@hellfish.ru',
        firstName: 'Иван',
        lastName: 'Иванов',
        middleName: 'Иванович',
        phone: '+7 920 105 47 17',
        position: 'Администратор',
        companyName: 'ООО Адская рыба',
        siteStatus: 'Администратор',
        profileFile: ''
      };
    });
  }
}

class Users extends UsersInterface {
  me() {
    return axios.get(`/api/v1/users/me/`);
  }
  getUserById(id){
    return axios.get(`/api/v1/users/${id}/`, {id});
  }
  update(id, userData){
    return axios.put(`/api/v1/users/${id}/`, userData);
  }
  upload(id, formData){
    return axios.post(`/api/v1/users/${id}/upload/`, formData);
  }
  getAllClients(limit, offset, desc, sortType){
    return axios.get(`/api/v1/users/?account_type=client&limit=${limit}&offset=${offset}&sort=${desc}${sortType}`, {limit, offset, desc, sortType})
  }
  getAllManagers() {
    return axios.get('/api/v1/users/?account_type=manager')
  }
  searchClients(query){
    return axios.get(`api/v1/users/?query=${query}`, {query})
  }
  addClient(
      email,
      phone,
      company_name,
      company_address,
      name,
      surname,
      middle_name,
      position,
      password,
  ) {
    const manager = ''
    return axios.post(`/api/v1/auth/signup/`, {
      email,
      phone,
      company_name,
      company_address,
      first_name: name,
      last_name: surname,
      middle_name,
      position,
      password,
      manager,
    });
  }

  deactivateClient(id){
    return axios.get(`/api/v1/users/${id}/deactivate/`);
  }
  activateClient(id){
    return axios.get(`/api/v1/users/${id}/activate/`);
  }
  remove(id){
    return axios.delete(`/api/v1/users/${id}/`)
  }
  setRoleManager(id){
    return axios.post(`/api/v1/users/${id}/set_role/?account_type=MANAGER`)

  }
  setRoleClient(id){
    return axios.post(`/api/v1/users/${id}/set_role/?account_type=CLIENT`)

  }
}

export default function usersFactory() {
  if (MOCK_USERS_API) {
    return new MockUsers();
  } else {
    return new Users();
  }
}
