import { DEBUG, MOCK_AUTH_API, BACKEND_BASE_URL } from "./../../constants.js";
import axios from "axios";

class PostsInterface {

}

class MockPosts extends PostsInterface {

}

class Posts extends PostsInterface {
  // Получение постов
  getPosts(query, category) {
    if (query && category){
      return axios.get(`api/v1/blog/posts/?offset=0&query=${query}&category=${category}`)
    }
    if (query){
      return axios.get(`api/v1/blog/posts/?offset=0&query=${query}`);
    }
    if (category){
      return axios.get(`api/v1/blog/posts/?offset=0&category=${category}`);
    } else
    return axios.get(`api/v1/blog/posts/?offset=0`);
  }
  getPostBySlug(slug){
    return axios.get(`/api/v1/blog/posts/?offset=0&slug=${slug}`);
  }
  getPostById(id){
    return axios.get(`/api/v1/blog/posts/${id}`);
  }
  getRecentPosts(){
    return axios.get(`/api/v1/blog/posts/?offset=0&limit=3`)
  }

  // Добавление / модификация / удаление постов
  addPost(postData){
    return axios.post(`/api/v1/blog/posts/`, postData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
  }
  updatePost(id, postData) {
    return axios.put(`/api/v1/blog/posts/${id}/`, postData)
  }

  deletePost(id){
    return axios.delete(`/api/v1/blog/posts/${id}`);
  }

  // Комментарии
  getComments(id){
    return axios.get(`/api/v1/blog/posts/${id}/comments/?offset=0`);
  }
  addComment(id, text){
    return axios.post(`/api/v1/blog/posts/${id}/comments/`, {
      text,
    });
  }

  // Категории
  getCategories(){
    return axios.get(`/api/v1/blog/categories/?offset=0`);
  }
  addNewCategory(title, picture){
    return axios.post(`api/v1/blog/categories/`, {
      title,
      picture,
    });
  }
  deleteCategory(id){
    return axios.delete(`api/v1/blog/categories/${id}`, {
      id
    });
  }
  editCategory(id, title, picture){
    return axios.put(`api/v1/blog/categories/${id}/`, {
      title, picture
    });
  }
}

export default function postsFactory() {
  if (MOCK_AUTH_API) {
    return new MockPosts();
  } else {
    return new Posts();
  }
}
